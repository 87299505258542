var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',{staticClass:"insights"},[_c('b-container',{staticClass:"mt-5"},[_c('b-card',{attrs:{"no-body":""}},[(_vm.showHeader)?_c('PulsCardHeader',{attrs:{"title":_vm.organizationFullName,"leftAction":{
              to: { name: 'overview', title: _vm.$t('integrations.back') }
            }}}):_vm._e(),(_vm.mostRecentCompletedSurvey)?_c('div',{staticClass:"col-12 col-md-6"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedGroupName),expression:"selectedGroupName"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedGroupName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Entire org")]),_vm._l((_vm.mostRecentCompletedSurvey.details.groups),function(item){return _c('option',{key:item.name,domProps:{"value":item.name}},[_vm._v(" "+_vm._s(item.messages.en.title)+" ")])})],2)]):_vm._e(),(_vm.groupNameForInsights)?_c('b-card-body',{key:_vm.selectedGroupName},[_c('b-row',[_c('b-col',[(_vm.api)?_c('Insights',{attrs:{"locale":_vm.locale,"groupName":_vm.groupNameForInsights,"surveyName":"ALL","api":_vm.api,"tr":_vm.$tr,"area":"team","numberOfInsightsDetails":6,"hideStatItemTypes":{
                    categories: {
                      statItemNames: [],
                      results: ['low', 'medium', 'high']
                    }
                  }}}):_vm._e()],1),_c('b-col',[(_vm.api)?_c('Insights',{attrs:{"locale":_vm.locale,"groupName":_vm.groupNameForInsights,"surveyName":"ALL","api":_vm.api,"tr":_vm.$tr,"area":"leadership","shouldHideDetailedInfo":true,"numberOfInsightsDetails":0}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }