<template>
  <div class="">
    <div class="">
      <div class="insights">
        <b-container class="mt-5">
          <b-card no-body>
            <PulsCardHeader
              v-if="showHeader"
              :title="organizationFullName"
              :leftAction="{
                to: { name: 'overview', title: $t('integrations.back') }
              }"
            />
            <div v-if="mostRecentCompletedSurvey" class="col-12 col-md-6">
              <select v-model="selectedGroupName" class="form-control">
                <option value="">Entire org</option>
                <option
                  v-for="item in mostRecentCompletedSurvey.details.groups"
                  :key="item.name"
                  :value="item.name"
                >
                  {{ item.messages.en.title }}
                </option>
              </select>
            </div>
            <b-card-body v-if="groupNameForInsights" :key="selectedGroupName">
              <b-row>
                <b-col>
                  <Insights
                    v-if="api"
                    :locale="locale"
                    :groupName="groupNameForInsights"
                    surveyName="ALL"
                    :api="api"
                    :tr="$tr"
                    area="team"
                    :numberOfInsightsDetails="6"
                    :hideStatItemTypes="{
                      categories: {
                        statItemNames: [],
                        results: ['low', 'medium', 'high']
                      }
                    }"
                  />
                </b-col>
                <b-col>
                  <Insights
                    v-if="api"
                    :locale="locale"
                    :groupName="groupNameForInsights"
                    surveyName="ALL"
                    :api="api"
                    :tr="$tr"
                    area="leadership"
                    :shouldHideDetailedInfo="true"
                    :numberOfInsightsDetails="0"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { Insights } from '@puls-solutions/puls-insights-vue'
import '@puls-solutions/puls-insights-vue/dist/puls-insights-vue.css'
import { PulsCardHeader } from '@puls-solutions/puls-ui-components'

export default {
  name: 'InsightsView',
  components: { PulsCardHeader, Insights },
  props: {
    showHeader: { type: Boolean, default: true }
  },
  data() {
    return {
      api: null,
      selectedGroupName: null
    }
  },
  mounted() {
    this.api = API
    this.fetchSurveys()
    this.selectedGroupName = this.organizationName
  },
  // watch: {
  //   // Whenever selectedGroupName changes this function will be executed
  //   selectedGroupName: function(newGroupName, oldGroupName) {
  //     if (newGroupName) {

  //     }
  //   }
  // },
  computed: {
    groupNameForInsights() {
      return this.selectedGroupName || this.organizationName
    },
    locale() {
      return this.$i18n.locale
    },
    organizationName() {
      return this.$store.getters.userInfo.organizationName
    },
    organizationFullName() {
      return this.$store.getters.userInfo.organizationFullName
    },
    surveys() {
      return this.$store.getters.getResultsSurveys || []
    },
    mostRecentCompletedSurvey() {
      const allCompletedSurveysSortedByTime = this.surveys
        .filter((s) => s.details.status === 'completed')
        .sort((a, b) => {
          return b.details.start_date.localeCompare(a.details.start_date)
        })

      if (allCompletedSurveysSortedByTime.length) {
        return allCompletedSurveysSortedByTime[0]
      } else {
        return null
      }
    }
  },
  methods: {
    fetchSurveys() {
      this.$store.dispatch('loadResultsSurveys', {
        organizationName: this.organizationName
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
